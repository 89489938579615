<template>
  <c-flex
    position="relative"
    :margin-right="[
      images?.length === 1
        ? '0':
          images?.length === 2
            ? '14px'
            : '21px',
      images?.length === 1
        ? '0'
        : images?.length === 2
          ? '17px'
          : '26px'
    ]"
  >
    <c-image
      position="relative"
      border-radius="50%"
      :src="getPhotoUser(images?.[0])"
      :height="['25px', '30px']"
      :width="['25px', '30px']"
      z-index="3"
    />
    <c-image
      position="absolute"
      left="60%"
      top="0"
      border-radius="50%"
      :src="getPhotoUser(images?.[1])"
      :height="['25px', '30px']"
      :width="['25px', '30px']"
      style="scale: 0.85;"
      z-index="2"
    />
    <c-image
      position="absolute"
      left="100%"
      border-radius="50%"
      :src="getPhotoUser(images?.[2])"
      :height="['25px', '30px']"
      :width="['25px', '30px']"
      style="scale: 0.65;"
      z-index="1"
    />
  </c-flex>
</template>

<script>
import { CFlex, CImage } from '@chakra-ui/vue'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'ProfileStack',
  components: { CFlex, CImage },
  mixins: [
    generalMixin,
  ],
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
}
</script>
