<template>
  <c-flex
    width="100%"
    justify-content="space-between"
    align-items="center"
    background-color="secondary.50"
    :padding="['10px', '10px 20px']"
    :gap="['10px', '30px']"
    :border-radius="['8px', '12px']"
    box-shadow="0px 2px 8px 0px #00000026"
    :height="['fit-content', '80px']"
  >
    <c-flex
      flex-grow="1"
      align-items="center"
      :gap="['10px', '30px']"
    >
      <c-image
        :src="require('@/assets/icons/icon-points.svg')"
        height="auto"
        :width="['40px', '60px']"
      />
      <BaseText
        size-mobile="16px"
        size-desktop="20px"
      >
        {{ point ?? 0 }} Poin
      </BaseText>
    </c-flex>
    <BaseButton
      v-if="withButton"
      flex-shrink="0"
      :font-size="['14px', '16px']"
      @click="$emit('onClickButton')"
    >
      Tukar Sekarang
    </BaseButton>
  </c-flex>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'CardReferralPoint',  
  components: {
    BaseText,
    BaseButton,
  },
  props: {
    point: {
      type: [String, Number, null],
      required: true,
    },
    withButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickButton'],
}
</script>

<style>

</style>