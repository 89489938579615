<template>
  <c-box
    position="relative"
    width="100%"
  >
    <c-box
      width="100%"
      background-color="#F2F2F2"
    >
      <c-box
        :display="['none', 'flex']"
        px="30px"
        py="5px"
        background-color="#FFF"
      >
        <c-flex
          width="100%"
          max-width="1270px"
          margin-inline="auto"
          align-items="center"
          gap="80px"
        >
          <c-box
            display="flex"
            align-items="center"
            font-size="16px"
            font-weight="400"
            color="primary.400"
            gap="20px"
            cursor="pointer"
            @click="$router.push({ name: 'client.profile' })"
          >
            <inline-svg
              :src="require('@/assets/icon-chevron-left.svg')"
              height="20"
              width="20"
            />
            Kembali
          </c-box>
          
          <BreadcrumbPath 
            margin-top="0px"
            :paths="[
              { label: 'Dashboard', href: '/' },
              { label: 'Profil', href: '/profile' },
              { label: 'Kode AjakSehat', isCurrent: true },
            ]"
          />
        </c-flex>
      </c-box>
      
      <c-flex
        position="relative"
        flex-direction="column"
        justify-content="center"
      >
        <c-flex  
          position="relative"
          width="100%"
          flex-direction="column"
          justify-content="center"
          z-index="1"
          overflow="hidden"
        >
          <c-image
            v-if="referral?.bannerData?.bannerUrl"
            :src="referral?.bannerData?.bannerUrl"
            position="absolute"
            width="100%"
            object-fit="cover"
            filter="blur(6px)"
            z-index="1"
          />
          <c-image
            v-if="referral?.bannerData?.bannerUrl"
            :src="referral?.bannerData?.bannerUrl"
            width="100%"
            :max-height="['300px']"
            object-fit="contain"
            z-index="2"
          />
          <c-box
            v-else
            display="block"
            width="100%"
            :height="['100px', '300px']"
            z-index="2"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              animation="fade"
            />
          </c-box>
        </c-flex>
        <c-flex 
          width="100%"
          flex-direction="column"
          background-color="#FFF"
          :margin-top="['-35px', '-60px']"
          margin-inline="auto"
          margin-bottom="16px"
          :max-width="['calc(100% - 2rem)', '750px']"
          :padding="['10px 12px', '20px 80px 10px 80px']"
          :gap="['4px', '6px']"
          border-radius="8px"
          box-shadow="2px 2px 10px 0px #00000026"
          z-index="2"
        >
          <c-text
            :font-size="['12px', '16px']"
            :font-weight="[400, 500]"
            color="#555"
          >
            Kode Referral
          </c-text>
          <c-flex
            justify-content="space-between"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
            padding-bottom="5px"
          >
            <c-text
              :font-size="['18px', '24px']"
              :font-weight="[500, 700]"
              color="primary.400"
            >
              {{ referral?.referralData?.code ?? '-' }}
            </c-text>
            <input
              ref="company_code"
              type="hidden"
              :value="referral?.referralData?.code"
            >
            <c-flex
              cursor="pointer"
              gap="6px"
              :font-size="['12px', '16px']"
              font-weight="500"
              color="primary.400"
              @click="copyClipboard('company_code')"
            >
              Salin
              <c-image
                :src="require('@/assets/icons/icon-copy.svg')"
                :width="['16px', '24px']"
                :height="['16px', '24px']"
              />
            </c-flex>
          </c-flex>
          <transition
            name="slide-down"
          >
            <c-box
              v-if="isShowSteps"
              as="ol"
              list-style-position="outside"
              padding-left="16px"
              color="#555"
            >
              <c-box
                v-for="(step, i) in referral?.howToShare"
                :key="i"
                as="li"
                :font-size="['12px', '16px']"
                font-weight="400"
              >
                {{ step.item }}
              </c-box>
            </c-box>
          </transition>
          <c-flex
            justify-content="center"
            align-items="center"
            gap="8px"
            :font-size="['12px', '16px']"
            color="#555555"
            cursor="pointer"
            @click="onToggleShowSteps"
          >
            Cara Bagikan
            <c-box
              :transform="isShowSteps ? 'rotate(-180deg)' : 'rotate(0)'"
              animation="1s transform ease"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                class="icon-steps"
                fill="#111"
              />
            </c-box>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>

    <c-box
      max-width="1270px"
      width="100%"
      margin-inline="auto"
      :margin-top="['0px', '30px']"
      :padding="['12px 16px', '30px 20px']"
      :border-radius="['0px', '12px']"
      box-shadow="2px 2px 10px 0px #00000026"
    >
      <c-flex
        :flex-direction="['column', 'row']"
        :gap="['16px', '50px']"
      >
        <!-- SECTION DAFTAR PENGGUNA KODE -->
        <c-flex
          :width="['100%']"
          :max-width="['100%', '500px']"
          :gap="['8px', '16px']"
          flex-direction="column"
          :order="['2', '1']"
        >
          <c-flex
            gap="8px"
            justify-content="space-between"
            align-items="center"
          >
            <BaseText
              size-mobile="14px-3"
              size-desktop="28px"
              white-space="nowrap"
            >
              Daftar Pengguna Kode
            </BaseText>

            <c-flex
              gap="8px"
              align-items="center"
              cursor="pointer"
              :padding="['0px', '8px']"
              :_hover="{
                backgroundColor: '#F2F2F2',
                borderRadius: '8px',
              }"
              @click="$router.push({ name: 'client.profile.referral.detail' })"
            >
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                color="neutral.darkGray"
                white-space="nowrap"
              >
                Lihat semua
              </BaseText>
              <inline-svg
                :src="require('@/assets/icon-chevron-right.svg')"
                class="icon-chevron-right-md"
                stroke="#323232"
              />
            </c-flex>
          </c-flex>

          <c-flex
            gap="8px"
            flex-direction="column"
            :box-shadow="['unset', '2px 2px 10px 0px #00000026']"
          >
            <c-flex
              gap="8px"
              align-items="center"
              :padding="['0', '8px 16px 0 16px']"
            >
              <ProfileStack
                v-if="(referral?.infoClientUse?.length || 0) > 0"
                :images="referral?.infoClientUse?.map((item) => item?.photoUrl)"
              />
              <inline-svg
                v-else
                :src="require('@/assets/icons/icon-profile-square.svg')"
                height="24px"
                width="24px"
                fill="#555555"
              />
              <BaseText
                size-mobile="14px-2"
                size-desktop="18px"
              >
                {{ referral?.totalClientUse }} Orang
              </BaseText>
            </c-flex>

            <c-box
              padding="0 16px"
            >
              <c-box
                as="ul"
                list-style-type="none"
              >
                <c-box
                  v-for="(ref, refIdx) in referral?.infoClientUse || []"
                  :key="refIdx"
                  as="li"
                >
                  <c-flex
                    padding="6px 0"
                    justify-content="space-between"
                    border-bottom="1px solid #F2F2F2"
                  >
                    <c-text
                      :font-size="['14px','16px']"
                      font-weight="400"
                    >
                      {{ refIdx+1 }}. {{ ref?.email || '-' }}
                    </c-text>
                    <c-text
                      :font-size="['12px','14px']"
                      font-weight="400"
                      color="primary.400"
                    >
                      {{ ref?.poin ? `+${ref?.poin} poin` : "-" }}
                    </c-text>
                  </c-flex>
                </c-box>
              </c-box>
            </c-box>
          </c-flex>
        </c-flex>

        <c-flex
          min-width="0"
          width="100%"
          flex-direction="column"
          :order="['1', '2']"
        >
          <!-- SECTION POIN KAMU -->
          <c-flex
            :gap="['8px', '16px']"
            flex-direction="column"
          >
            <c-text
              :font-size="['14px', '28px']"
              :font-weight="['400', '700']"
              color="#111"
            >
              Poin Kamu
            </c-text>
            <CardReferralPoint
              :point="poin"
              :with-button="isKol ? false : true"
              @onClickButton="$router.push({ name: 'client.profile.referral.redeem' })"
            />
          </c-flex>

          <!-- SECTION TARGET ACHIEVEMENT -->
          <c-box
            :margin-top="['8px', '20px']"
            :box-shadow="['unset','2px 2px 10px 0px #00000026']"
            :padding="['8px 16px']"
          >
            <c-text
              :display="['block', 'none']"
              :font-size="['14px', '28px']"
              font-weight="700"
              color="#111"
            >
              Bonus Poin
            </c-text>
            <c-flex
              :margin-top="['16px', '0']"
              overflow="auto"
              width="100%"
              max-width="100%"
            >
              <StepperDot
                v-if="extraPointAchievementReformat.length > 0"
                :steps="extraPointAchievementReformat"
              />
            </c-flex>
          </c-box>

          <c-box
            as="hr"
            :display="['block', 'none']"
          />
        </c-flex>
      </c-flex>

      <!-- Hadiah Populer -->
      <PopulerGift
        v-if="isKol ? false : true"
      />

      <!-- FAQ -->
      <c-box :margin-top="['8px', '16px']">
        <c-text
          :text-align="['left', 'center']"
          :font-weight="['500','700']"
          :font-size="['14px','28px']"
          color="black"
          font-family="Roboto"
        >
          FAQ
        </c-text>
        <c-accordion
          v-if="faqs.length > 0"
          :default-index="[]"
          :allow-multiple="false"
          :allow-toggle="true"
          d="grid"
          :grid-template-columns="['1fr', '1fr 1fr']"
          :grid-template-rows="['auto', `masonry`]"
          mx="auto"
          row-gap="8px"
          column-gap="30px"
          :px="['0px', '30px']"
          py="8px"
        >
          <c-accordion-item
            v-for="(item,index) in faqs"
            v-slot="{ isExpanded }"
            :key="index"
            v-chakra="{
              ':hover': {
                backgroundColor: 'white',
              },
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'white !important',
              },
            }"
            w="100%"
            bg="white"
            border-radius="8px"
            h="fit-content"
            font-family="Roboto"
            border="none"
          >
            <c-accordion-header
              v-chakra="{
                ':hover': {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'white !important',
                  borderRadius: '8px',
                },
              }"
              :h="['auto','64px']"
            >
              <c-box
                flex="1"
                text-align="left"
                :font-size="['14px', '16px']"
                :font-weight="['400']"
                color="#000000"
                pr="20px"
              >
                {{ (index+1) + '. ' + item.question }}
              </c-box>
              <c-image
                :src="require('@/assets/icon-accordion.svg')"
                :h="['10px']"
                :w="['5px']"
                :transform="!isExpanded ? 'rotate(0)' : 'rotate(-90deg)'"
                animation="1s transform ease"
              />
            </c-accordion-header>
            <c-accordion-panel
              pb="4"
              :font-size="['14px', '16px']"
              :font-weight="['400']"
              color="#000000"
              text-align="justify"
              font-family="Roboto"
            >
              <c-text
                v-for="(item_, index_) in splitString(
                  item.answer
                )"
                :key="index_"
                font-family="Roboto"
              >
                <c-text
                  font-family="Roboto"
                  v-html="item_ === '' ? '<br>': item_"
                />
              </c-text>
            </c-accordion-panel>
          </c-accordion-item>
        </c-accordion>
      </c-box>

      <!-- SECTION TERMS AND CONDITIONS -->
      <c-box
        :background-color="['white','neutral.superLightGray']"
        :padding="['10px 0', '10px']"
      >
        <c-text
          :font-size="['14px','20px']"
          font-weight="700"
          :text-align="['start', 'center']"
        >
          Syarat dan Ketentuan
        </c-text>
        <c-box
          margin-top="8px"
          as="ol"
          padding-left="32px"
          :font-size="['12px','16px']"
          font-weight="400"
        >
          <c-box
            v-for="(term, termIdx) in ['sm', 'md'].includes(currentBreakpoint) ? listTermsAndConditions.slice(0, 2) : listTermsAndConditions"
            :key="termIdx"
            as="li"
            text-align="justify"
          >
            {{ term.value }}
          </c-box>
        </c-box>
        <c-box
          margin-top="8px"
          as="button"
          :display="['flex', 'none']"
          width="100%"
          justify-content="center"
          padding="6px"
          border-radius="8px"
          :_hover="{
            backgroundColor: '#F2F2F2',
          }"
          @click="isOpenDrawerTermsAndCondition = true"
        >
          <c-text
            display="flex"
            gap="4px"
            align-items="center"
            font-size="12px"
            font-weight="400"
            color="neutral.darkGray"
          >
            Lihat Lebih Lengkap
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down-2.svg')"
              height="20"
              width="20"
            />
          </c-text>
        </c-box>
      </c-box>
    </c-box>

    <!-- DRAWER TERMS AND CONDITIONS (Mobile) -->
    <c-drawer
      placement="bottom"
      :on-close="() => {isOpenDrawerTermsAndCondition = false}"
      :is-open="isOpenDrawerTermsAndCondition"
      :initial-focus-ref="() => {}"
    >
      <c-drawer-overlay />
      <c-drawer-content rounded-top="12px">
        <c-drawer-body position="relative">
          <c-drawer-close-button
            position="absolute"
            top="8px"
            background-color="transparent"
            right="8px"
          />
          <c-box
            height="300px"
            overflow="auto"
            padding-top="10px"
          >
            <c-text
              font-size="14px"
              font-weight="700"
              text-align="center"
            >
              Syarat dan Ketentuan
            </c-text>
            <c-box
              margin-top="10px"
              as="ol"
              padding-left="20px"
              :font-size="['12px','16px']"
              font-weight="400"
            >
              <c-box
                v-for="(term, termIdx) in listTermsAndConditions"
                :key="termIdx"
                as="li"
                text-align="justify"
              >
                {{ term.value }}
              </c-box>
            </c-box>
          </c-box>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-box>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'
import { mapActions, mapGetters } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import CardReferralPoint from '@/components/cards/card-referral-point.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import { CBox, CImage, CText, CFlex, CAccordion, CAccordionItem, CAccordionHeader, CAccordionPanel, CDrawer, CDrawerBody, CDrawerContent, CDrawerOverlay, CDrawerCloseButton } from '@chakra-ui/vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import ProfileStack from '@/views/profile/referral/_widgets/profile-stack.vue'
import StepperDot from '@/components/elements/stepper-dot.vue'
import PopulerGift from '@/views/profile/referral/_widgets/populer-gift.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'Referral',
  components: {
    StepperDot,
    // StepperDot,
    ProfileStack,
    BaseText,
    CardReferralPoint,
    VueSkeletonLoader,
    CBox,
    CImage,
    CText,
    CFlex,
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionPanel,
    CDrawer,
    CDrawerBody,
    CDrawerContent,
    CDrawerOverlay,
    CDrawerCloseButton,
    PopulerGift,
    BreadcrumbPath,
  },
  mixins: [
    mixinsCheckBreakpoints(),
  ],
  data() {
    return {
      isShowSteps: false,
      listTermsAndConditions: [],
      isOpenDrawerTermsAndCondition: false,
      extraPointAchievement: [],
    }
  },
  computed: {
    ...mapGetters({
      referral: 'referral/referral',
      faqs: 'referral/faqs',
      poin: 'referral/poin',
      isKol: 'auth/isKol',
    }),
    extraPointAchievementReformat() {
      return this?.extraPointAchievement?.map((item) => ({
        ...item,
        title: item?.totalPoin,
        label: item?.label,
        isCurrentStep: Boolean(item?.isAchieved),
      })) || []
    },
  },
  mounted() {
    this.getReferral()
    this.getFaqs()
    this.getPoin()
    reqReferral.termsAndConditions(this.$store.getters.axios).then((v) => {
      this.listTermsAndConditions = v?.data?.data?.sort((a, b) => a.order - b.order) || []
    })
    reqReferral.extraPointAchievement(this.$store.getters.axios).then((v) => {
      this.extraPointAchievement = v?.data?.data || []
    })
  },
  methods: {
    ...mapActions({
      getReferral: 'referral/getReferral',
      getFaqs: 'referral/getFaqs',
      getPoin: 'referral/getPoin',
    }),
    onToggleShowSteps() {
      this.isShowSteps = !this.isShowSteps
    },
    copyClipboard(ref) {
      const el = this.$refs[ref]
      try {
        el.setAttribute('type', 'text')
        el.select()
        document.execCommand('copy')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil disalin',
          duration: 2000,
        })
        this.isShowSteps = false
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: 'Oops, unable to copy',
          duration: 2000,
        })
      }
      el.setAttribute('type', 'hidden')
    },
    
    splitString(string) {
      if (!string) return ''
      return string.split('\r\n')  
    },
  },
}
</script>

<style scoped>
.icon-steps {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .icon-steps {
    width: 12px;
    height: 12px;
  }
}
</style>