<template>
  <c-box
    :margin-top="['16px', '24px']"
    :padding-inline="['0px', '30px']"
  >
    <c-flex
      :margin-bottom="['8px', '16px']"
      justify-content="space-between"
      align-items="center"
    >
      <c-text
        :font-size="['14px', '20px']"
        :font-weight="['500', '700']"
      >
        Hadiah Populer
      </c-text>
      <c-flex
        gap="8px"
        align-items="center"
        cursor="pointer"
        :padding="['0px', '8px']"
        :_hover="{
          backgroundColor: '#F2F2F2',
          borderRadius: '8px',
        }"
        @click="$router.push({ name: 'client.profile.referral.redeem' })"
      >
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '500']"
          color="#555555"
        >
          Lihat semua
        </c-text>
        <inline-svg
          v-chakra
          :src="require('@/assets/icon-chevron-right.svg')"
          class="icon-chevron-right-md"
          stroke="#111"
        />
      </c-flex>
    </c-flex>
    <c-box>
      <SkeletonSimilarGift
        v-if="isLoading"
      />
      <VueSlickCarousel
        v-if="items.length > 0 && !isLoading"
        v-chakra="{
          '.slick-slide': {
            padding: '0 16px 8px 0',
            '@media(max-width: 767px)': {
              padding: '0 8px 8px 0',
            },
          },
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item) in items"
          :key="item?.id"
        >
          <c-flex
            width="100%"
            :max-width="['120px', '260px']"
            flex-direction="column"
            background-color="#FFF"
            :border-radius="['8px', '12px']"
            gap="8px"
            :box-shadow="['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026']"
            overflow="hidden"
            cursor="pointer"
            @click="onClickGift(item)"
          >
            <c-image
              :src="item?.image"
              :width="['120px', '260px']"
              :height="['70px', '145px']"
              object-fit="cover"
            />
            <c-text
              :font-size="['10px', '16px']"
              font-weight="500"
              color="#111"
              padding-inline="8px"
              padding-bottom="8px"
            >
              {{ item?.name }}
            </c-text>
          </c-flex>
        </c-box>
      </VueSlickCarousel>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { reqReferral } from '@/requests/dietela-api/referral'
import SkeletonSimilarGift from '@/views/profile/referral/_widgets/skeleton-similar-gift.vue'

export default {
  components: {
    VueSlickCarousel,
    SkeletonSimilarGift,
  },
  props: {},
  data() {
    return {
      items: [],
      isLoading: false,
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        variableWidth: true,
        slidesToScroll: 1,
      }, 
    }
  },
  computed: {},
  async mounted() {
    this.isLoading = true
    try {
      const res = await reqReferral.gifts(this.$store.getters.axios, {
        page: 1,
        perpage: 5,
      })
      this.items = res.data?.data?.rows
    } catch (err) {
      console.log(err?.response?.data?.message)
    }
    this.isLoading = false
  },
  methods: {
    onClickGift(item) {
      this.$router.push({
        name: 'client.profile.referral.gift.detail',
        params: {
          id: item?.id,
        },
      }).catch(() => {})
    },
  },
}
</script>

<style>

</style>