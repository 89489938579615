var render = function () {
  var _vm$images, _vm$images2, _vm$images3, _vm$images4, _vm$images5, _vm$images6, _vm$images7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "margin-right": [((_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images.length) === 1 ? '0' : ((_vm$images2 = _vm.images) === null || _vm$images2 === void 0 ? void 0 : _vm$images2.length) === 2 ? '14px' : '21px', ((_vm$images3 = _vm.images) === null || _vm$images3 === void 0 ? void 0 : _vm$images3.length) === 1 ? '0' : ((_vm$images4 = _vm.images) === null || _vm$images4 === void 0 ? void 0 : _vm$images4.length) === 2 ? '17px' : '26px']
    }
  }, [_c('c-image', {
    attrs: {
      "position": "relative",
      "border-radius": "50%",
      "src": _vm.getPhotoUser((_vm$images5 = _vm.images) === null || _vm$images5 === void 0 ? void 0 : _vm$images5[0]),
      "height": ['25px', '30px'],
      "width": ['25px', '30px'],
      "z-index": "3"
    }
  }), _c('c-image', {
    staticStyle: {
      "scale": "0.85"
    },
    attrs: {
      "position": "absolute",
      "left": "60%",
      "top": "0",
      "border-radius": "50%",
      "src": _vm.getPhotoUser((_vm$images6 = _vm.images) === null || _vm$images6 === void 0 ? void 0 : _vm$images6[1]),
      "height": ['25px', '30px'],
      "width": ['25px', '30px'],
      "z-index": "2"
    }
  }), _c('c-image', {
    staticStyle: {
      "scale": "0.65"
    },
    attrs: {
      "position": "absolute",
      "left": "100%",
      "border-radius": "50%",
      "src": _vm.getPhotoUser((_vm$images7 = _vm.images) === null || _vm$images7 === void 0 ? void 0 : _vm$images7[2]),
      "height": ['25px', '30px'],
      "width": ['25px', '30px'],
      "z-index": "1"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }