var render = function () {
  var _vm$referral, _vm$referral$bannerDa, _vm$referral2, _vm$referral2$bannerD, _vm$referral3, _vm$referral3$bannerD, _vm$referral4, _vm$referral4$bannerD, _vm$referral$referral, _vm$referral5, _vm$referral5$referra, _vm$referral6, _vm$referral6$referra, _vm$referral7, _vm$referral8, _vm$referral8$infoCli, _vm$referral9, _vm$referral9$infoCli, _vm$referral10, _vm$referral11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "#F2F2F2"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'flex'],
      "px": "30px",
      "py": "5px",
      "background-color": "#FFF"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "margin-inline": "auto",
      "align-items": "center",
      "gap": "80px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400",
      "gap": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile'
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-left.svg'),
      "height": "20",
      "width": "20"
    }
  }), _vm._v(" Kembali ")], 1), _c('BreadcrumbPath', {
    attrs: {
      "margin-top": "0px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Profil',
        href: '/profile'
      }, {
        label: 'Kode AjakSehat',
        isCurrent: true
      }]
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "position": "relative",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "z-index": "1",
      "overflow": "hidden"
    }
  }, [(_vm$referral = _vm.referral) !== null && _vm$referral !== void 0 && (_vm$referral$bannerDa = _vm$referral.bannerData) !== null && _vm$referral$bannerDa !== void 0 && _vm$referral$bannerDa.bannerUrl ? _c('c-image', {
    attrs: {
      "src": (_vm$referral2 = _vm.referral) === null || _vm$referral2 === void 0 ? void 0 : (_vm$referral2$bannerD = _vm$referral2.bannerData) === null || _vm$referral2$bannerD === void 0 ? void 0 : _vm$referral2$bannerD.bannerUrl,
      "position": "absolute",
      "width": "100%",
      "object-fit": "cover",
      "filter": "blur(6px)",
      "z-index": "1"
    }
  }) : _vm._e(), (_vm$referral3 = _vm.referral) !== null && _vm$referral3 !== void 0 && (_vm$referral3$bannerD = _vm$referral3.bannerData) !== null && _vm$referral3$bannerD !== void 0 && _vm$referral3$bannerD.bannerUrl ? _c('c-image', {
    attrs: {
      "src": (_vm$referral4 = _vm.referral) === null || _vm$referral4 === void 0 ? void 0 : (_vm$referral4$bannerD = _vm$referral4.bannerData) === null || _vm$referral4$bannerD === void 0 ? void 0 : _vm$referral4$bannerD.bannerUrl,
      "width": "100%",
      "max-height": ['300px'],
      "object-fit": "contain",
      "z-index": "2"
    }
  }) : _c('c-box', {
    attrs: {
      "display": "block",
      "width": "100%",
      "height": ['100px', '300px'],
      "z-index": "2"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "background-color": "#FFF",
      "margin-top": ['-35px', '-60px'],
      "margin-inline": "auto",
      "margin-bottom": "16px",
      "max-width": ['calc(100% - 2rem)', '750px'],
      "padding": ['10px 12px', '20px 80px 10px 80px'],
      "gap": ['4px', '6px'],
      "border-radius": "8px",
      "box-shadow": "2px 2px 10px 0px #00000026",
      "z-index": "2"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": [400, 500],
      "color": "#555"
    }
  }, [_vm._v(" Kode Referral ")]), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "5px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '24px'],
      "font-weight": [500, 700],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$referral$referral = (_vm$referral5 = _vm.referral) === null || _vm$referral5 === void 0 ? void 0 : (_vm$referral5$referra = _vm$referral5.referralData) === null || _vm$referral5$referra === void 0 ? void 0 : _vm$referral5$referra.code) !== null && _vm$referral$referral !== void 0 ? _vm$referral$referral : '-') + " ")]), _c('input', {
    ref: "company_code",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$referral6 = _vm.referral) === null || _vm$referral6 === void 0 ? void 0 : (_vm$referral6$referra = _vm$referral6.referralData) === null || _vm$referral6$referra === void 0 ? void 0 : _vm$referral6$referra.code
    }
  }), _c('c-flex', {
    attrs: {
      "cursor": "pointer",
      "gap": "6px",
      "font-size": ['12px', '16px'],
      "font-weight": "500",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        return _vm.copyClipboard('company_code');
      }
    }
  }, [_vm._v(" Salin "), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-copy.svg'),
      "width": ['16px', '24px'],
      "height": ['16px', '24px']
    }
  })], 1)], 1), _c('transition', {
    attrs: {
      "name": "slide-down"
    }
  }, [_vm.isShowSteps ? _c('c-box', {
    attrs: {
      "as": "ol",
      "list-style-position": "outside",
      "padding-left": "16px",
      "color": "#555"
    }
  }, _vm._l((_vm$referral7 = _vm.referral) === null || _vm$referral7 === void 0 ? void 0 : _vm$referral7.howToShare, function (step, i) {
    return _c('c-box', {
      key: i,
      attrs: {
        "as": "li",
        "font-size": ['12px', '16px'],
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(step.item) + " ")]);
  }), 1) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px",
      "font-size": ['12px', '16px'],
      "color": "#555555",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.onToggleShowSteps
    }
  }, [_vm._v(" Cara Bagikan "), _c('c-box', {
    attrs: {
      "transform": _vm.isShowSteps ? 'rotate(-180deg)' : 'rotate(0)',
      "animation": "1s transform ease"
    }
  }, [_c('inline-svg', {
    staticClass: "icon-steps",
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "fill": "#111"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "max-width": "1270px",
      "width": "100%",
      "margin-inline": "auto",
      "margin-top": ['0px', '30px'],
      "padding": ['12px 16px', '30px 20px'],
      "border-radius": ['0px', '12px'],
      "box-shadow": "2px 2px 10px 0px #00000026"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "gap": ['16px', '50px']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": ['100%'],
      "max-width": ['100%', '500px'],
      "gap": ['8px', '16px'],
      "flex-direction": "column",
      "order": ['2', '1']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "8px",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-3",
      "size-desktop": "28px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Daftar Pengguna Kode ")]), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "align-items": "center",
      "cursor": "pointer",
      "padding": ['0px', '8px'],
      "_hover": {
        backgroundColor: '#F2F2F2',
        borderRadius: '8px'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile.referral.detail'
        });
      }
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "color": "neutral.darkGray",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Lihat semua ")]), _c('inline-svg', {
    staticClass: "icon-chevron-right-md",
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "stroke": "#323232"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "flex-direction": "column",
      "box-shadow": ['unset', '2px 2px 10px 0px #00000026']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "8px",
      "align-items": "center",
      "padding": ['0', '8px 16px 0 16px']
    }
  }, [(((_vm$referral8 = _vm.referral) === null || _vm$referral8 === void 0 ? void 0 : (_vm$referral8$infoCli = _vm$referral8.infoClientUse) === null || _vm$referral8$infoCli === void 0 ? void 0 : _vm$referral8$infoCli.length) || 0) > 0 ? _c('ProfileStack', {
    attrs: {
      "images": (_vm$referral9 = _vm.referral) === null || _vm$referral9 === void 0 ? void 0 : (_vm$referral9$infoCli = _vm$referral9.infoClientUse) === null || _vm$referral9$infoCli === void 0 ? void 0 : _vm$referral9$infoCli.map(function (item) {
        return item === null || item === void 0 ? void 0 : item.photoUrl;
      })
    }
  }) : _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-profile-square.svg'),
      "height": "24px",
      "width": "24px",
      "fill": "#555555"
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s((_vm$referral10 = _vm.referral) === null || _vm$referral10 === void 0 ? void 0 : _vm$referral10.totalClientUse) + " Orang ")])], 1), _c('c-box', {
    attrs: {
      "padding": "0 16px"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "ul",
      "list-style-type": "none"
    }
  }, _vm._l(((_vm$referral11 = _vm.referral) === null || _vm$referral11 === void 0 ? void 0 : _vm$referral11.infoClientUse) || [], function (ref, refIdx) {
    return _c('c-box', {
      key: refIdx,
      attrs: {
        "as": "li"
      }
    }, [_c('c-flex', {
      attrs: {
        "padding": "6px 0",
        "justify-content": "space-between",
        "border-bottom": "1px solid #F2F2F2"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(refIdx + 1) + ". " + _vm._s((ref === null || ref === void 0 ? void 0 : ref.email) || '-') + " ")]), _c('c-text', {
      attrs: {
        "font-size": ['12px', '14px'],
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(ref !== null && ref !== void 0 && ref.poin ? "+".concat(ref === null || ref === void 0 ? void 0 : ref.poin, " poin") : "-") + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "min-width": "0",
      "width": "100%",
      "flex-direction": "column",
      "order": ['1', '2']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": ['8px', '16px'],
      "flex-direction": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '28px'],
      "font-weight": ['400', '700'],
      "color": "#111"
    }
  }, [_vm._v(" Poin Kamu ")]), _c('CardReferralPoint', {
    attrs: {
      "point": _vm.poin,
      "with-button": _vm.isKol ? false : true
    },
    on: {
      "onClickButton": function onClickButton($event) {
        return _vm.$router.push({
          name: 'client.profile.referral.redeem'
        });
      }
    }
  })], 1), _c('c-box', {
    attrs: {
      "margin-top": ['8px', '20px'],
      "box-shadow": ['unset', '2px 2px 10px 0px #00000026'],
      "padding": ['8px 16px']
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['block', 'none'],
      "font-size": ['14px', '28px'],
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" Bonus Poin ")]), _c('c-flex', {
    attrs: {
      "margin-top": ['16px', '0'],
      "overflow": "auto",
      "width": "100%",
      "max-width": "100%"
    }
  }, [_vm.extraPointAchievementReformat.length > 0 ? _c('StepperDot', {
    attrs: {
      "steps": _vm.extraPointAchievementReformat
    }
  }) : _vm._e()], 1)], 1), _c('c-box', {
    attrs: {
      "as": "hr",
      "display": ['block', 'none']
    }
  })], 1)], 1), (_vm.isKol ? false : true) ? _c('PopulerGift') : _vm._e(), _c('c-box', {
    attrs: {
      "margin-top": ['8px', '16px']
    }
  }, [_c('c-text', {
    attrs: {
      "text-align": ['left', 'center'],
      "font-weight": ['500', '700'],
      "font-size": ['14px', '28px'],
      "color": "black",
      "font-family": "Roboto"
    }
  }, [_vm._v(" FAQ ")]), _vm.faqs.length > 0 ? _c('c-accordion', {
    attrs: {
      "default-index": [],
      "allow-multiple": false,
      "allow-toggle": true,
      "d": "grid",
      "grid-template-columns": ['1fr', '1fr 1fr'],
      "grid-template-rows": ['auto', "masonry"],
      "mx": "auto",
      "row-gap": "8px",
      "column-gap": "30px",
      "px": ['0px', '30px'],
      "py": "8px"
    }
  }, _vm._l(_vm.faqs, function (item, index) {
    return _c('c-accordion-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'white'
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none !important',
            background: 'white !important'
          }
        },
        expression: "{\n            ':hover': {\n              backgroundColor: 'white',\n            },\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'white !important',\n            },\n          }"
      }],
      key: index,
      attrs: {
        "w": "100%",
        "bg": "white",
        "border-radius": "8px",
        "h": "fit-content",
        "font-family": "Roboto",
        "border": "none"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var isExpanded = _ref.isExpanded;
          return [_c('c-accordion-header', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                ':hover': {
                  backgroundColor: 'white',
                  borderRadius: '8px'
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'white !important',
                  borderRadius: '8px'
                }
              },
              expression: "{\n              ':hover': {\n                backgroundColor: 'white',\n                borderRadius: '8px',\n              },\n              ':focus': {\n                outline: 'none',\n                boxShadow: 'none !important',\n                background: 'white !important',\n                borderRadius: '8px',\n              },\n            }"
            }],
            attrs: {
              "h": ['auto', '64px']
            }
          }, [_c('c-box', {
            attrs: {
              "flex": "1",
              "text-align": "left",
              "font-size": ['14px', '16px'],
              "font-weight": ['400'],
              "color": "#000000",
              "pr": "20px"
            }
          }, [_vm._v(" " + _vm._s(index + 1 + '. ' + item.question) + " ")]), _c('c-image', {
            attrs: {
              "src": require('@/assets/icon-accordion.svg'),
              "h": ['10px'],
              "w": ['5px'],
              "transform": !isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
              "animation": "1s transform ease"
            }
          })], 1), _c('c-accordion-panel', {
            attrs: {
              "pb": "4",
              "font-size": ['14px', '16px'],
              "font-weight": ['400'],
              "color": "#000000",
              "text-align": "justify",
              "font-family": "Roboto"
            }
          }, _vm._l(_vm.splitString(item.answer), function (item_, index_) {
            return _c('c-text', {
              key: index_,
              attrs: {
                "font-family": "Roboto"
              }
            }, [_c('c-text', {
              attrs: {
                "font-family": "Roboto"
              },
              domProps: {
                "innerHTML": _vm._s(item_ === '' ? '<br>' : item_)
              }
            })], 1);
          }), 1)];
        }
      }], null, true)
    });
  }), 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "background-color": ['white', 'neutral.superLightGray'],
      "padding": ['10px 0', '10px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '20px'],
      "font-weight": "700",
      "text-align": ['start', 'center']
    }
  }, [_vm._v(" Syarat dan Ketentuan ")]), _c('c-box', {
    attrs: {
      "margin-top": "8px",
      "as": "ol",
      "padding-left": "32px",
      "font-size": ['12px', '16px'],
      "font-weight": "400"
    }
  }, _vm._l(['sm', 'md'].includes(_vm.currentBreakpoint) ? _vm.listTermsAndConditions.slice(0, 2) : _vm.listTermsAndConditions, function (term, termIdx) {
    return _c('c-box', {
      key: termIdx,
      attrs: {
        "as": "li",
        "text-align": "justify"
      }
    }, [_vm._v(" " + _vm._s(term.value) + " ")]);
  }), 1), _c('c-box', {
    attrs: {
      "margin-top": "8px",
      "as": "button",
      "display": ['flex', 'none'],
      "width": "100%",
      "justify-content": "center",
      "padding": "6px",
      "border-radius": "8px",
      "_hover": {
        backgroundColor: '#F2F2F2'
      }
    },
    on: {
      "click": function click($event) {
        _vm.isOpenDrawerTermsAndCondition = true;
      }
    }
  }, [_c('c-text', {
    attrs: {
      "display": "flex",
      "gap": "4px",
      "align-items": "center",
      "font-size": "12px",
      "font-weight": "400",
      "color": "neutral.darkGray"
    }
  }, [_vm._v(" Lihat Lebih Lengkap "), _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down-2.svg'),
      "height": "20",
      "width": "20"
    }
  })], 1)], 1)], 1)], 1), _c('c-drawer', {
    attrs: {
      "placement": "bottom",
      "on-close": function onClose() {
        _vm.isOpenDrawerTermsAndCondition = false;
      },
      "is-open": _vm.isOpenDrawerTermsAndCondition,
      "initial-focus-ref": function initialFocusRef() {}
    }
  }, [_c('c-drawer-overlay'), _c('c-drawer-content', {
    attrs: {
      "rounded-top": "12px"
    }
  }, [_c('c-drawer-body', {
    attrs: {
      "position": "relative"
    }
  }, [_c('c-drawer-close-button', {
    attrs: {
      "position": "absolute",
      "top": "8px",
      "background-color": "transparent",
      "right": "8px"
    }
  }), _c('c-box', {
    attrs: {
      "height": "300px",
      "overflow": "auto",
      "padding-top": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "14px",
      "font-weight": "700",
      "text-align": "center"
    }
  }, [_vm._v(" Syarat dan Ketentuan ")]), _c('c-box', {
    attrs: {
      "margin-top": "10px",
      "as": "ol",
      "padding-left": "20px",
      "font-size": ['12px', '16px'],
      "font-weight": "400"
    }
  }, _vm._l(_vm.listTermsAndConditions, function (term, termIdx) {
    return _c('c-box', {
      key: termIdx,
      attrs: {
        "as": "li",
        "text-align": "justify"
      }
    }, [_vm._v(" " + _vm._s(term.value) + " ")]);
  }), 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }