import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0e4ffa17&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0e4ffa17&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4ffa17",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
