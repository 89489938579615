var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', _vm._b({
    attrs: {
      "flex-direction": "row",
      "align-items": "start",
      "gap": "32px"
    }
  }, 'c-flex', _vm.$attrs, false), _vm._l(_vm.steps, function (step, index) {
    return _c('c-box', {
      key: index,
      ref: "stepperRef",
      refInFor: true,
      attrs: {
        "d": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "center",
        "width": ['60px', '100px'],
        "min-width": ['60px', '120px']
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "font-weight": ['400', '500'],
        "font-size": ['12px', '16px'],
        "text-align": "center"
      }
    }, [_c('c-badge', {
      attrs: {
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "font-size": ['12px', '12px'],
        "bg": _vm.colorStep(step, index),
        "color": "white",
        "border-radius": "50%",
        "text-align": "center",
        "line-height": "32px",
        "w": ['32px', '62px'],
        "h": ['32px', '62px'],
        "position": "relative",
        "margin-bottom": "4px"
      }
    }, [_vm._v(" " + _vm._s((step === null || step === void 0 ? void 0 : step.title) || index + 1) + " "), !_vm.isFirstStep(index) ? _c('c-box', {
      attrs: {
        "height": ['12px', '16px'],
        "width": ['12px', '16px'],
        "position": "absolute",
        "bottom": "2px",
        "left": ["calc(".concat(_vm.arrowPositionEl, " - 12px)")],
        "color": _vm.colorStep(step, index),
        "top": ['0', '17px'],
        "font-size": ['12px', '14px']
      }
    }, [_vm._v(" •••••• ")]) : _vm._e()], 1), _c('c-text', {
      attrs: {
        "font-weight": "400",
        "font-size": ['10px', '14px']
      }
    }, [_vm._v(" " + _vm._s(step.label) + " ")])], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }