var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": ['16px', '24px'],
      "padding-inline": ['0px', '30px']
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": ['8px', '16px'],
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '20px'],
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Hadiah Populer ")]), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "align-items": "center",
      "cursor": "pointer",
      "padding": ['0px', '8px'],
      "_hover": {
        backgroundColor: '#F2F2F2',
        borderRadius: '8px'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile.referral.redeem'
        });
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '500'],
      "color": "#555555"
    }
  }, [_vm._v(" Lihat semua ")]), _c('inline-svg', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    staticClass: "icon-chevron-right-md",
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "stroke": "#111"
    }
  })], 1)], 1), _c('c-box', [_vm.isLoading ? _c('SkeletonSimilarGift') : _vm._e(), _vm.items.length > 0 && !_vm.isLoading ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 8px 8px 0'
          }
        }
      },
      expression: "{\n        '.slick-slide': {\n          padding: '0 16px 8px 0',\n          '@media(max-width: 767px)': {\n            padding: '0 8px 8px 0',\n          },\n        },\n      }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.items, function (item) {
    return _c('c-box', {
      key: item === null || item === void 0 ? void 0 : item.id
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "max-width": ['120px', '260px'],
        "flex-direction": "column",
        "background-color": "#FFF",
        "border-radius": ['8px', '12px'],
        "gap": "8px",
        "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
        "overflow": "hidden",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickGift(item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.image,
        "width": ['120px', '260px'],
        "height": ['70px', '145px'],
        "object-fit": "cover"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": ['10px', '16px'],
        "font-weight": "500",
        "color": "#111",
        "padding-inline": "8px",
        "padding-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }