var render = function () {
  var _vm$point;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "secondary.50",
      "padding": ['10px', '10px 20px'],
      "gap": ['10px', '30px'],
      "border-radius": ['8px', '12px'],
      "box-shadow": "0px 2px 8px 0px #00000026",
      "height": ['fit-content', '80px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": ['10px', '30px']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-points.svg'),
      "height": "auto",
      "width": ['40px', '60px']
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" " + _vm._s((_vm$point = _vm.point) !== null && _vm$point !== void 0 ? _vm$point : 0) + " Poin ")])], 1), _vm.withButton ? _c('BaseButton', {
    attrs: {
      "flex-shrink": "0",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClickButton');
      }
    }
  }, [_vm._v(" Tukar Sekarang ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }